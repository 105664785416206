<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <v-select
          v-model="form.ability_id"
          label="Ability"
          :items="abilityOptions"
          item-text="title"
          item-value="value"
        ></v-select>

        <v-select
          v-model="form.when"
          label="When event happend"
          :items="whenOptions"
          item-text="title"
          item-value="value"
        ></v-select>

        <v-text-field
          v-model="form.delay_days"
          label="Delay N days when event happend."
          type="number"
        >
        </v-field>
        </v-text-field>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslationLocales } from '@/components'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'
import useAbilityList from './useAbilityList'

export default {
  components: { TranslatableInput, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      ability_id: null,
      delay_days: 0,
    }

    const { abilityOptions, loadAbilities } = useAbilityList()
    loadAbilities({ take: 999 })

    const whenOptions = [
      { title: 'User has booking(application) completed.', value: 'user_quota_application.completed' },
    ]

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      form.value.delay_days = parseInt(form.value.delay_days)

      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      abilityOptions,
      whenOptions,

      form,
      formElem,

      validate,
      required,
    }
  },
}
</script>
