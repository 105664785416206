import { destroyGain, destroyNeed, showAbilitySource } from '@/api/common/ability'
import { ref } from '@vue/composition-api'

export default () => {
  const source = ref(null)

  const fetchAbilitySource = (type, id) => showAbilitySource(type, id).then(response => {
    source.value = response.data.data
  })

  const deleteGain = gain => destroyGain(gain.id)

  const deleteNeed = need => destroyNeed(need.id)

  return {
    source,
    fetchAbilitySource,
    deleteGain,
    deleteNeed,
  }
}
