var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[(_vm.formReady && _vm.aside === 'resource')?_c('quota-rule-aside',{attrs:{"resource":_vm.resource},on:{"changed":function($event){_vm.loadQuotaRules(); _vm.sidebarActive = false; _vm.aside = ''}},model:{value:(_vm.sidebarActive),callback:function ($$v) {_vm.sidebarActive=$$v},expression:"sidebarActive"}}):_vm._e(),(_vm.formReady && _vm.aside === 'abilities')?_c('model-abilities-aside',{attrs:{"resource":_vm.resource},on:{"changed":function($event){_vm.loadQuotaRules(); _vm.sidebarActive = false; _vm.aside = ''}},model:{value:(_vm.sidebarActive),callback:function ($$v) {_vm.sidebarActive=$$v},expression:"sidebarActive"}}):_vm._e(),_c('v-row',{staticClass:"mb-5"},_vm._l((_vm.totalLocal),function(total){return _c('v-col',{key:total.total,attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-card',[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between pa-4"},[_c('div',[_c('h2',{staticClass:"font-weight-semibold mb-1"},[_vm._v(" "+_vm._s(total.total)+" ")]),_c('span',[_vm._v(_vm._s(total.title))])])])],1)],1)}),1),_c('v-card',[_c('v-card-title',[_vm._v(" Search & Filter ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}}),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.addNewResoure.apply(null, arguments)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New Rule")])],1)],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.quotaRuleListTable,"options":_vm.options,"server-items-length":_vm.totalListTable,"loading":_vm.loading,"show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.interval_count",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.interval_count)+" "+_vm._s(item.interval_unit)+"(s)")])]}},{key:"item.quota",fn:function(ref){
var item = ref.item;
return [(item.quota === -1)?_c('span',[_vm._v("UNLIMITED")]):_c('span',[_vm._v(_vm._s(item.quota))])]}},{key:"item.rechargeable_id",fn:function(ref){
var item = ref.item;
return [(item.rechargeable)?_c('router-link',{attrs:{"to":{
            name: 'product-view',
            params: { id: item.rechargeable.product_id }
          }}},[_vm._v(" "+_vm._s(item.rechargeable.name.en)+" ")]):_vm._e(),_vm._l((item.abilities.gains),function(gain){return _c('div',{key:gain.key},[_vm._v(" (Gain "),_c('b',[_vm._v(_vm._s(gain.key))]),_vm._v(" when "),_c('b',[_vm._v(_vm._s(gain.when))]),_vm._v(" "),(gain.delay_days)?_c('span',[_vm._v("with "),_c('b',[_vm._v(_vm._s(gain.delay_days))]),_vm._v(" days delay")]):_vm._e(),_vm._v(") ")])}),_vm._l((item.abilities.needs),function(need){return _c('div',{key:need.key},[_vm._v(" (Need "),_c('b',[_vm._v(_vm._s(need.key))]),_vm._v(" when "),_c('b',[_vm._v(_vm._s(need.when))]),_vm._v(") ")])}),(!item.enable_recharge)?_c('div',[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCloseCircle))]),_vm._v(" [Recharage disabled] ")],1):_vm._e()]}},{key:"item.application_rule",fn:function(ref){
          var item = ref.item;
return [(item.application_rule.require_slot)?_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiClockOutline)+" ")]):_vm._e(),(item.application_rule.require_destination)?[(item.application_rule.destination_types.includes('user_address'))?_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiTruck)+" ")]):_vm._e(),(item.application_rule.destination_types.includes('branch'))?_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiHome)+" ")]):_vm._e()]:_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.setQuotaRule(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('span',[_vm._v("Update")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.manageAbilities(item)}}},[_c('v-list-item-title',[_c('span',[_vm._v("Manage Ability")])])],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }