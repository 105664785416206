<template>
  <div id="user-list">
    <!-- app drawer -->
    <quota-rule-aside
      v-if="formReady && aside === 'resource'"
      v-model="sidebarActive"
      :resource="resource"
      @changed="loadQuotaRules(); sidebarActive = false; aside = ''"
    ></quota-rule-aside>

    <model-abilities-aside
      v-if="formReady && aside === 'abilities'"
      v-model="sidebarActive"
      :resource="resource"
      @changed="loadQuotaRules(); sidebarActive = false; aside = ''"
    ></model-abilities-aside>

    <!-- user total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in totalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>

      <v-row>
        <v-col cols="6"></v-col>
        <v-col
          cols="6"
          class="text-right"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            @click.stop="addNewResoure"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Rule</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="quotaRuleListTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        show-select
      >
        <template #[`item.interval_count`]="{item}">
          <span>{{ item.interval_count }} {{ item.interval_unit }}(s)</span>
        </template>

        <template #[`item.quota`]="{item}">
          <span v-if="item.quota === -1">UNLIMITED</span>
          <span v-else>{{ item.quota }}</span>
        </template>

        <template #[`item.rechargeable_id`]="{item}">
          <router-link
            v-if="item.rechargeable"
            :to="{
              name: 'product-view',
              params: { id: item.rechargeable.product_id }
            }"
          >
            {{ item.rechargeable.name.en }}
          </router-link>

          <div
            v-for="gain in item.abilities.gains"
            :key="gain.key"
          >
            (Gain <b>{{ gain.key }}</b> when <b>{{ gain.when }}</b> <span v-if="gain.delay_days">with <b>{{ gain.delay_days }}</b> days delay</span>)
          </div>
          <div
            v-for="need in item.abilities.needs"
            :key="need.key"
          >
            (Need <b>{{ need.key }}</b> when <b>{{ need.when }}</b>)
          </div>

          <div v-if="!item.enable_recharge">
            <v-icon>{{ icons.mdiCloseCircle }}</v-icon>
            [Recharage disabled]
          </div>
        </template>

        <template #[`item.application_rule`]="{item}">
          <v-icon v-if="item.application_rule.require_slot">
            {{ icons.mdiClockOutline }}
          </v-icon>
          <template v-if="item.application_rule.require_destination">
            <v-icon v-if="item.application_rule.destination_types.includes('user_address')">
              {{ icons.mdiTruck }}
            </v-icon>
            <v-icon v-if="item.application_rule.destination_types.includes('branch')">
              {{ icons.mdiHome }}
            </v-icon>
          </template>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="setQuotaRule(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Update</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                @click="manageAbilities(item)"
              >
                <v-list-item-title>
                  <!-- <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon> -->
                  <span>Manage Ability</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
mdiClockOutline,
mdiCloseCircle,
mdiDotsVertical,
mdiHome,
mdiPencil,
mdiPlus,
mdiTruck,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'

import ModelAbilitiesAside from '@/modules/common/views/ability/ModelAbilitiesAside.vue'
import { t } from '@/plugins/i18n'
import QuotaRuleAside from './QuotaRuleAside.vue'

import useQuotaRuleList from './useQuotaRuleList'

export default {
  components: {
    QuotaRuleAside,
    ModelAbilitiesAside,
  },
  setup() {
    const {
      quotaRuleListTable,
      tableColumns,
      searchQuery,
      totalListTable,
      loading,
      options,
      totalLocal,
      selectedRows,

      loadQuotaRules,
      updateAllQuotaRules,
    } = useQuotaRuleList()

    const sidebarActive = ref(false)
    const formReady = ref(true)
    const resource = ref(false)
    const aside = ref(false)

    const rootQuotaRuleName = quotaRules => {
      const quotaRule = quotaRules.find(c => !c.parent_id)

      return quotaRule ? t(quotaRule.name) : ''
    }

    // const quotaRuleegoriesOptions = commonStore.state.quotaRuleegories.map(quotaRule => ({ title: field(quotaRule.name), value: quotaRule.id }))

    const setQuotaRule = quotaRule => {
      resource.value = quotaRule
      sidebarActive.value = true
      formReady.value = false
      aside.value = 'resource'
      setTimeout(() => {
        formReady.value = true
      })
    }

    const addNewResoure = () => {
      resource.value = null
      formReady.value = false
      sidebarActive.value = true
      aside.value = 'resource'
      setTimeout(() => {
        formReady.value = true
      })
    }

    const manageAbilities = quotaRule => {
      resource.value = quotaRule
      formReady.value = false
      sidebarActive.value = true
      aside.value = 'abilities'
      setTimeout(() => {
        formReady.value = true
      })
    }

    return {
      quotaRuleListTable,
      tableColumns,
      searchQuery,
      totalListTable,
      aside,
      manageAbilities,

      // categoriesOptions,
      loading,
      options,
      totalLocal,
      sidebarActive,
      formReady,
      resource,
      selectedRows,
      avatarText,
      loadQuotaRules,
      setQuotaRule,
      addNewResoure,
      updateAllQuotaRules,
      rootQuotaRuleName,

      t,

      // icons
      icons: {
        mdiPencil,
        mdiDotsVertical,
        mdiPlus,
        mdiClockOutline,
        mdiTruck,
        mdiHome,
        mdiCloseCircle,
      },
    }
  },
}
</script>

    <style lang="scss">
    @import '@core/preset/preset/apps/user.scss';
    </style>
