<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 380 : '100%'"
    app
    @input="(val) => $emit('aside', val)"
  >
    <div
      v-if="source"
      class="px-4 py-4"
    >
      <h2 class="mt-4 mb-2">
        Gains
      </h2>
      <div
        v-for="gain in source.gains"
        :key="gain.ability.key"
      >
        (Gain <b>{{ gain.ability.key }}</b> when <b>{{ gain.when }}</b> <span v-if="gain.delay_days">with <b>{{ gain.delay_days }}</b> days delay</span>)

        <a @click="handleDeleteGain(gain)">REMOVE</a>
      </div>
      <a
        v-if="!adding"
        class="mt-2"
        @click="adding = 'gain'"
      >+ADD</a>

      <ModelAbilitiesGainForm
        v-if="adding === 'gain'"
        @submit="handleGainSubmit"
      />

      <h2 class="mt-4 mb-2">
        Needs
      </h2>
      <div
        v-for="need in source.needs"
        :key="need.key"
      >
        (Need <b>{{ need.key }}</b> when <b>{{ need.when }}</b>)
        <a @click="handleDeleteNeed(need)">REMOVE</a>
      </div>
      <a
        v-if="!adding"
        class="mt-2"
        @click="adding = 'need'"
      >+ADD</a>

      <ModelAbilitiesNeedForm
        v-if="adding === 'need'"
        @submit="handleNeedSubmit"
      />
    </div>
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { storeAbilityGain, storeAbilityNeed } from '@api/common/ability'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import ModelAbilitiesGainForm from './ModelAbilitiesGainForm.vue'
import ModelAbilitiesNeedForm from './ModelAbilitiesNeedForm.vue'
import useAbility from './useAbility'

export default {
  components: { ModelAbilitiesGainForm, ModelAbilitiesNeedForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      default: 'quota-rule',
    },
    resource: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])
    const adding = ref('')

    const {
      source, fetchAbilitySource, deleteGain, deleteNeed,
    } = useAbility()

    const reset = () => fetchAbilitySource(props.type, props.resource.id)

    reset()

    const handleGainSubmit = async form => {
      loading.value = true
      const request = storeAbilityGain(props.type, {
        ...form,
        source_id: props.resource.id,
      })
      await request
        .then(async res => {
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    const handleNeedSubmit = async form => {
      loading.value = true
      const request = storeAbilityNeed(props.type, {
        ...form,
        source_id: props.resource.id,
      })
      await request
        .then(async res => {
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    const handleDeleteGain = gain => {
      deleteGain(gain).then(() => {
        reset()
        emit('changed')
      })
    }

    const handleDeleteNeed = need => {
      deleteNeed(need).then(() => {
        reset()
        emit('changed')
      })
    }

    return {
      reset,
      source,
      adding,
      handleGainSubmit,
      handleNeedSubmit,
      loading,
      errors,

      handleDeleteGain,
      handleDeleteNeed,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
